import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import MetaData from "../components/meta"
import Masonry from "masonry-layout"
import getRedirectUrl from "../utils/getRedirectUrl"

// Import styles
import "../styles/success-stories.scss"

const SuccessStoriesPage = ({ data, location }) => {
  const featured_story = data.featured_story
  let stories = data.stories.edges

  stories = stories.map(({ node }) => {
    node.hide_title = false
    node.has_quote = false
    node.hide_link = false
    node.link_text = "Read story"
    node.story_category = ""

    node.tags.forEach(tag => {
      if (tag.slug === "hash-hide-title") {
        node.hide_title = true
        return
      }

      if (tag.slug === "hash-has-quote") {
        node.has_quote = true
        return
      }

      if (tag.slug === "hash-hide-link") {
        node.hide_link = true
        return
      }

      if (tag.slug === "hash-video") {
        node.link_text = "Watch video"
        return
      }

      if (tag.visibility === "public" && tag.slug !== "success-stories") {
        node.story_category = tag.name
        return
      }
    })

    return node
  })

  const getFeaturedStoryCtaText = tags => {
    let arr = tags.filter(tag => {
      return tag.slug === "hash-video"
    })
    if (arr.length > 0) {
      return "Watch video"
    } else {
      return "Read story"
    }
  }

  useEffect(() => {
    var msnry = new Masonry(".customer-stories-wrapper", {
      itemSelector: ".customer-story",
    })
  }, [])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="customer-stories-page">
        {/* Featured Story */}
        {featured_story && (
          <div className="customer-story--featured">
            <div className="wrapper-1200">
              <div className="content">
                <h2 className="customer-story__title">
                  {featured_story.title}
                </h2>
                {featured_story.custom_excerpt && (
                  <div className="customer-story__excerpt">
                    {featured_story.custom_excerpt}
                  </div>
                )}
                <div className="customer-story__link">
                  <a
                    className="button--primary button--with-shadow button--primary--small button--primary--with-arrow"
                    href={
                      getRedirectUrl(featured_story.codeinjection_head)
                        ? getRedirectUrl(featured_story.codeinjection_head)
                        : `/success-stories/${featured_story.slug}/`
                    }
                  >
                    {getFeaturedStoryCtaText(featured_story.tags)}
                  </a>
                </div>
              </div>

              <div className="feature-image">
                <img
                  src={
                    featured_story.featureImageSharp?.childImageSharp?.fluid
                      ?.src ||
                    featured_story?.featureImageSharp?.publicURL ||
                    featured_story.feature_image
                  }
                  alt={featured_story.title}
                />
              </div>
            </div>
          </div>
        )}

        {/* Stories */}
        <div className="customer-stories">
          <div className="wrapper-1200">
            <div className="customer-stories-wrapper">
              {stories.map(node => (
                <div className="customer-story" key={node.id}>
                  <div className="wrapper">
                    {!!(
                      node.featureImageSharp?.childImageSharp?.fluid?.src ||
                      node.featureImageSharp?.publicURL ||
                      node.feature_image
                    ) && (
                      <img
                        src={
                          node.featureImageSharp?.childImageSharp?.fluid?.src ||
                          node.featureImageSharp?.publicURL ||
                          node.feature_image
                        }
                        className="customer-story__feature-image"
                        alt={node.title}
                      />
                    )}

                    <div className="customer-story__category">
                      {node.story_category}
                    </div>

                    {!node.hide_title && (
                      <h2 className="customer-story__title">{node.title}</h2>
                    )}

                    {node.has_quote && (
                      <div
                        className="customer-story__testimonial-wrapper"
                        dangerouslySetInnerHTML={{
                          __html: node.childHtmlRehype.html,
                        }}
                      ></div>
                    )}

                    {!node.hide_link && (
                      <a
                        href={
                          getRedirectUrl(node.codeinjection_head)
                            ? getRedirectUrl(node.codeinjection_head)
                            : `/success-stories/${node.slug}/`
                        }
                        className="customer-story__link link--with-arrow"
                      >
                        {node.link_text}
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA */}
        <section className="containous-cta-1">
          <div className="wrapper-1200">
            <h2>Ready to get started?</h2>
            <div className="links">
              <a
                href="https://info.traefik.io/en/request-demo-traefik-enterprise"
                className="button--primary button--with-shadow button--extra-padding"
              >
                Request a demo
              </a>
              <a
                href="https://info.traefik.io/contact-sales"
                className="button--silver button--with-shadow button--extra-padding"
              >
                Contact us
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    featured_story: ghostPost(
      primary_tag: { slug: { eq: "success-stories" } }
      featured: { eq: true }
    ) {
      id
      title
      tags {
        slug
        name
        visibility
      }
      slug
      feature_image
      featureImageSharp {
        publicURL
      }
      custom_excerpt
      codeinjection_head
    }

    # All success stories except featured stories
    stories: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: "success-stories" } }
        featured: { eq: false }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          primary_tag {
            slug
          }
          tags {
            visibility
            slug
            name
          }
          feature_image
          featureImageSharp {
            publicURL
          }
          html
          childHtmlRehype {
            html
          }
          codeinjection_head
        }
      }
    }

    current_page: ghostPage(slug: { eq: "success-stories" }) {
      ...PageMetaFields
    }
  }
`

export default SuccessStoriesPage
